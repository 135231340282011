import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import DiagonalArrowIcon from "../images/icons/diagonal-arrow.svg"
import HeroBg from "../images/icons/hero-11-bg.svg"

const PrivacyPolicyPage = () => {
  return (
    <Layout headerBg="bg-beige">
      <Seo title="Privacy Policy" />
      <section className="bg-beige pt-20 relative overflow-hidden">
        <div className="max-w-screen-xl mx-auto px-4 lg:px-6">
          <div className="flex -mx-4">
            <div className="w-full md:/w-1/2 lg:w-4/6 px-4">
              <div className="relative z-10 pb-72">
                <DiagonalArrowIcon
                  className="stroke-primary mr-2"
                  width="42px"
                  height="42px"
                />
                <h1 className="text-h0/m md:text-h0 mt-2 mb-2">
                  Privacy & Cookies Policy
                </h1>
              </div>
            </div>
            <div className="w-full md:/w-1/2 lg:w-2/6 px-4 hidden md:block">
              <div className="relative h-full">
                <div className="absolute top-0 left-0 h-full">
                  <HeroBg className="ml-14" width="451px" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-20 relative">
        <div className="max-w-screen-xl mx-auto px-4 lg:px-6">
          <div className="prose-entry">
            <h3>Privacy Policy</h3>
            <p>
              Your privacy is important to us. It is PeakMedia's policy to
              respect your privacy regarding any information we may collect from
              you across our website:{" "}
              <a href="https://www.peak11.com">https://www.peak11.com</a>, and
              other sites we own and operate.
            </p>
            <p>
              Our website may link to external sites that are not operated by
              us. Please be aware that we have no control over the content and
              practices of these sites, and cannot accept responsibility or
              liability for their respective privacy policies.
            </p>
            <p>
              You are free to refuse our request for your personal information,
              with the understanding that we may be unable to provide you with
              some of your desired services.
            </p>
            <p>
              Your continued use of our website will be regarded as acceptance
              of our practices around privacy and personal information. If you
              have any questions about how we handle user data and personal
              information, feel free to contact us at:{" "}
              <a href="mailto:gdpr@peak11.com">gdpr@peak11.com</a>.
            </p>
            <p>
              We have developed this Privacy Policy so that you can understand
              how we collect, process, disclose and use your personal
              information.
            </p>
            <h3>Controller</h3>
            <p>
              The Controller of your personal data is PeakMedia Sp. z o.o. with
              its registered office in Kraków, Pl. Na Groblach 21, 31-101
              Kraków, Poland (“PeakMedia”, “We”, “Us”).
            </p>
            <p>
              You can contact Us directly for information regarding the
              protection of your personal data at:{" "}
              <a href="mailto:gdpr@peak11.com">gdpr@peak11.com</a>
            </p>
            <h3>What data do we collect?</h3>
            <p>We collect the following data:</p>
            <ul>
              <li>
                Personal identification information: name, e-mail address;
              </li>
              <li>
                Any information and attachments that you provide to Us by
                sending your request via our contact form;
              </li>
              <li>
                IP address of the device you are using, when browsing our
                website.
              </li>
            </ul>
            <h3>How do we collect your data?</h3>
            <p>
              You directly provide Us with most of the data we collect. We
              collect data and process data when you:
            </p>
            <ul>
              <li>Send Us inquiry using our contact form or email;</li>
              <li>Apply for a job offer;</li>
              <li>Use or view our website.</li>
            </ul>
            <h3>How will we use your data?</h3>
            <p>
              We collect and process your personal data for the purposes set out
              below on the basis of our legitimate interest (Article 6 clause
              1(f) of GDPR):
            </p>
            <ul>
              <li>
                marketing communication, including marketing of our own
                services;
              </li>
              <li>business communication;</li>
              <li>
                contacting you to answer your inquiries sent through contact
                form, chat, e-mail, social media, telephone or any other means
                of communication;
              </li>
              <li>
                ensuring the security of the services provided electronically;
              </li>
              <li>analysing traffic on our website and the way it's used;</li>
              <li>
                defending ourselves against claims, both in court proceedings
                and in administrative or out-of-court proceedings;
              </li>
              <li>
                storing of data for archiving and accountability, debt
                collection and payments purposes.
              </li>
            </ul>
            <p>Your personal data will be processed:</p>
            <ul>
              <li>
                Personal data collected for the purpose of answering questions
                asked via the contact form, email or chat - until you request
                erasure or until withdrawal of your consent to storage;
              </li>
              <li>
                Personal data collected based on your consent - until it is
                revoked;
              </li>
              <li>
                Personal data used to process your data for tax and accounting
                purposes - for as long as required by law;
              </li>
              <li>
                Personal data collected for the purposes of recruitment - will
                be processed for the duration of the recruitment process or
                until you revoke your consent;
              </li>
              <li>
                Personal data collected under the contract for our services -
                for the contract duration and also on its termination for the
                maximum period of limitation provided for by law after
                termination of the contract;
              </li>
              <li>
                We store data which we process in relation to our legitimate
                interest until you file an objection.
              </li>
            </ul>
            <h3>Transfer of personal data</h3>
            <p>
              Your personal data may be transferred to entities cooperating with
              Us on the basis of appropriate agreements, including marketing
              agencies and partners providing technical services (development
              and maintenance of IT systems and websites) on the grounds of our
              legitimate interests as a data controller.
            </p>
            <p>
              Your personal data may be transferred outside the European
              Economic Area (EEA) to a third country, i.e. the USA, to entities
              meeting the required level of protection under the European
              Commission's decision of 12 July 2016, the so-called Privacy
              Shield. These entities comply with the rules established by the
              U.S. Department of Commerce under the EU-US Privacy Shield
              Framework Programs governing the collection, use and retention of
              personal data from European Union Member States.
            </p>
            <p>Such entities include third party service providers we use:</p>
            <ul>
              <li>
                Google Analytics - in order to collect and analyze aggregated
                (not personalized) information on the website use;
              </li>
              <li>Intercom - to enable chat communication;</li>
              <li>
                Facebook - to conduct social marketing and sales activities;
              </li>
              <li>
                LinkedIn - to conduct social marketing and sales activities.
              </li>
            </ul>
            <h3>How do we store your data?</h3>
            <p>
              We use technical and organizational measures to ensure protection
              of the processed personal data appropriate to the threats and
              categories of data protected, and in particular protects the data
              against unauthorized disclosure, removal by an unauthorized
              person, processing in violation of applicable regulations and
              change, loss, damage or destruction.
            </p>
            <p>
              We provide appropriate technical measures to prevent the
              acquisition and modification by unauthorized persons of personal
              data, which we process.
            </p>
            <h3>What are your data protection rights?</h3>
            <p>
              We would like to make sure you are fully aware of all of your data
              protection rights. Every user is entitled to the following:
            </p>
            <ul>
              <li>
                The right to access - You have the right to request Us for
                copies of your personal data. We may charge you a small fee for
                this service.
              </li>
              <li>
                The right to rectification - You have the right to request that
                We correct any information you believe is inaccurate. You also
                have the right to request Us to complete the information you
                believe is incomplete.
              </li>
              <li>
                The right to erasure - You have the right to request that We
                erase your personal data, under certain conditions.
              </li>
              <li>
                The right to restrict processing - You have the right to request
                that We restrict the processing of your personal data, under
                certain conditions.
              </li>
              <li>
                The right to object to processing - You have the right to object
                to Us processing of your personal data, under certain
                conditions.
              </li>
              <li>
                The right to data portability - You have the right to request
                that We transfer the data that we have collected to another
                organization, or directly to you, under certain conditions.
              </li>
            </ul>
            <p>Providing your personal data is voluntary.</p>
            <p>
              If you make a request, we have one month to respond to you. If it
              becomes necessary to extend this period, we will inform you of the
              reasons for such an extension. If you would like to exercise any
              of these rights, please contact us at the following e-mail
              address: <a href="mailto:gdpr@peak11.com">gdpr@peak11.com</a>.
            </p>
            <p>
              In matters not covered by this Privacy Policy, the provisions of
              the GDPR and other relevant provisions of Polish law shall apply
              accordingly.
            </p>
            <h3>Cookie Policy</h3>
            <p>
              Cookies are text files placed on your computer to collect standard
              Internet log information and visitor behavior information. When
              you visit our websites, we may collect information from you
              automatically through cookies or similar technology.
            </p>
            <h3>How do we use cookies?</h3>
            <p>
              We uses cookies in a range of ways to improve your experience on
              our website, including:
            </p>
            <ul>
              <li>statistical purposes;</li>
              <li>to ensure the proper functioning of the website;</li>
              <li>maintain a chat session.</li>
            </ul>
            <p>
              There are a number of different types of cookies, however, our
              website uses:
            </p>
            <ul>
              <li>
                Performance Cookies - enabling the collection of information on
                how the website is used;
              </li>
              <li>
                Functional Cookies - used to remember your previously selected
                preferences. These could include what language you prefer and
                location you are in;
              </li>
              <li>
                Security Cookies - used to detect misuse of authentication;
              </li>
              <li>
                Advertising Cookies - used to collect information about your
                visit to our website, the content you viewed, the links you
                followed and information about your browser, device, and your IP
                address. We sometimes sharessome limited aspects of this data
                with third parties for advertising purposes. We may also share
                online data collected through cookies with our advertising
                partners. This means that when you visit another website, you
                may be shown advertising based on your browsing patterns on our
                website;
              </li>
              <li>
                Cookies are not used to process or store personal data. They
                cannot be used to directly identify You and do not make any
                configuration changes in the browser or telecommunication
                devices.
              </li>
            </ul>
            <h3>How to manage cookies</h3>
            <p>
              You can set your browser not to accept cookies. However, in a few
              cases, some of our website features may not function as a result.
            </p>
            <p>
              You can get additional information on how to clear, enable and
              manage cookies in the most popular web browsers on the following
              pages:
            </p>
            <ul>
              <li>Chrome: https://support.google.com/chrome/answer/95647</li>
              <li>
                Safari:
                https://support.apple.com/en-gb/guide/safari/sfri11471/mac
              </li>
              <li>
                Firefox:
                https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox
              </li>
              <li>
                Opera: https://help.opera.com/en/latest/web-preferences/#cookies
              </li>
              <li>
                Internet Explorer:
                https://support.microsoft.com/en-us/help/278835/how-to-delete-cookie-files-in-internet-explorer
              </li>
            </ul>
            <h3>Privacy policies of other websites</h3>
            <p>
              Our website contains links to other websites. Our privacy policy
              applies only to our website, so if you click on a link to another
              website, you should read their privacy policy.
            </p>
            <h3>Changes to our privacy policy</h3>
            <p>
              We keep our privacy policy under regular review and place any
              updates on this web page.{" "}
            </p>
            <h3>How to contact us</h3>
            <p>
              If you have any questions about our privacy policy, the data we
              hold on you, or you would like to exercise one of your data
              protection rights, please do not hesitate to contact us at:{" "}
              <a href="mailto:gdpr@peak11.com">gdpr@peak11.com</a>.
            </p>
            <h3>How to contact the appropriate authority</h3>
            <p>
              Should you wish to report a complaint or if you feel that We have
              not addressed your concern in a satisfactory manner, you may
              contact the President of the Personal Data Protection Office
              (address: Personal Data Protection Office, ul. Stawki 2, 00-193
              Warsaw).
            </p>
            <p>This privacy policy was last updated on 1 December 2021.</p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default PrivacyPolicyPage
